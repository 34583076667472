var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-12"},[_c('b-card',{attrs:{"title":"General"}},[_c('validation-observer',{ref:"generalSettingsForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[(_vm.formData)?_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-cols-sm":"","content-cols-lg":"8","label":"Church Name"}},[_c('validation-provider',{ref:"church_name",attrs:{"name":"Church name","vid":"church_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{ref:"church_name_input",attrs:{"placeholder":"Church Name","name":"church_name","state":errors.length > 0 ? false:null},model:{value:(_vm.formData.general.church_name),callback:function ($$v) {_vm.$set(_vm.formData.general, "church_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.general.church_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-cols-sm":"","content-cols-lg":"8","label":"Church Site URL"}},[_c('validation-provider',{ref:"church_site_url",attrs:{"name":"Church Site URL","vid":"church_site_url","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Church Site URL","name":"church_site_url","state":errors.length > 0 ? false:null},model:{value:(_vm.formData.general.church_site_url),callback:function ($$v) {_vm.$set(_vm.formData.general, "church_site_url", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.general.church_site_url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"4","content-cols-sm":"","content-cols-lg":"8","label":"Service Times"}},[_c('validation-provider',{ref:"service_times",attrs:{"name":"Service Times","vid":"service_times","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"3","placeholder":"Service Times","name":"service_times","state":errors.length > 0 ? false:null},model:{value:(_vm.formData.general.service_times),callback:function ($$v) {_vm.$set(_vm.formData.general, "service_times", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.general.service_times"}}),_c('label',[_vm._v(" Please be sure to enter the service times exactly as shown in the example below:"),_c('br'),_c('br'),_vm._v(" Monday, 10am"),_c('br'),_vm._v(" Tuesday, 11:30am"),_c('br'),_vm._v(" Friday, 5pm"),_c('br'),_c('br'),_vm._v(" Use full day of week name, comma and hour. One service per line. ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1):_vm._e(),_c('b-card-footer',{staticClass:"no-padding mt-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-between",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_vm._v(" Reset ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"variant":"primary","disabled":invalid || _vm.isSubmitting,"type":"submit"}},[_vm._v(" Save changes ")])],1)],1)],1)],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-tabs
    v-model="tabIndex"
    content-class="pt-1"
    pills
  >
    <b-tab
      title="Visitor Form"
      :active="tabIndex === 0"
    >
      <EditCtaSettings />
    </b-tab>
    <b-tab
      title="Thank You Message"
      :active="tabIndex === 1"
    >
      <EditThankYouSettings />
    </b-tab>
    <!--    <b-tab-->
    <!--      title="Redirect"-->
    <!--      :active="tabIndex === 2"-->
    <!--    >-->
    <!--      <EditRedirectSettings />-->
    <!--    </b-tab>-->
    <b-tab
      title="General"
      :active="tabIndex === 2"
    >
      <EditGeneralSettings />
    </b-tab>
    <b-tab
      title="Installation"
      :active="tabIndex === 3"
    >
      <Installation />
    </b-tab>
        <b-tab
          title="Planning Center"
          :active="tabIndex === 4"
        >
          <PlanningCenterEdit />
        </b-tab>
  </b-tabs>
</template>

<script>
import EditCtaSettings from '@/views/pages/widgets/EditCtaSettings.vue'
import EditThankYouSettings from '@/views/pages/widgets/EditThankYouSettings.vue'
import EditRedirectSettings from '@/views/pages/widgets/EditRedirectSettings.vue'
import EditGeneralSettings from '@/views/pages/widgets/EditGeneralSettings.vue'
import Installation from '@/views/pages/account/Installation.vue'
import PlanningCenterEdit from '@/views/pages/integrations/planning-center/Edit.vue'
import {
  BTab,
  BTabs,
} from 'bootstrap-vue'

export default {
  components: {
    BTab,
    BTabs,
    EditCtaSettings,
    EditThankYouSettings,
    // EditRedirectSettings,
    EditGeneralSettings,
    Installation,
    PlanningCenterEdit,
  },
  data() {
    let activeTab = 0
    if (this.$route.hash.length === 2) {
      activeTab = this.$route.hash.substring(1)
    }
    return {
      tabIndex: activeTab,
    }
  },
}
</script>

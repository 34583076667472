<template>
  <div>
    <h4 class="mb-1">
      What do you want to happen once the visit is scheduled?
    </h4>
    <b-row>
      <b-col lg="6">
        <b-card>
          <b-form
            class="mt-2"
            sm="12"
          >
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Title"
                  label-for="title"
                  label-cols-md="4"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="Edit2Icon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="title"
                      v-model="formData.title"
                      placeholder="Title"
                      name="title"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Message"
                  label-for="message"
                  label-cols-md="4"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-textarea
                      id="message"
                      v-model="formData.message"
                      rows="8"
                      placeholder="Your message here"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Background"
                  label-for="file"
                  label-cols-md="4"
                >
                  <b-input-group
                    v-if="!formData.background_file_id"
                    class="input-group-merge"
                  >
                    <b-form-file
                      id="file"
                      class="form-control"
                      @change="uploadFile"
                    />
                  </b-input-group>
                  <b-input-group
                    v-if="formData.background_file_id"
                    class="input-group-merge"
                  >
                    <b-form-input
                      v-if="formData.background_file_name"
                      :value="formData.background_file_name"
                      readonly
                    />
                    <b-input-group-append>
                      <b-button
                        text="Button"
                        variant="outline-danger"
                        @click="deleteFile"
                      >
                        X
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Video URL"
                  label-for="video_url"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="video_url"
                    v-model="formData.video_url"
                    placeholder="Video URL"
                    name="video_url"
                    :state="formValid"
                    @keypress="checkVideoUrl($event)"
                    @change="checkVideoUrl($event)"
                    @focusout="checkVideoUrl($event)"
                  />
                  <b-form-invalid-feedback
                    tooltip
                    size="sm"
                  >
                    Invalid Youtube URL
                  </b-form-invalid-feedback>
                </b-form-group></b-col>
              <b-col cols="12">
                <b-form-group
                  label="Button text"
                  label-for="button_text"
                  label-cols-md="4"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="Edit2Icon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="button_text"
                      v-model="formData.button_text"
                      placeholder="Button text"
                      name="button_text"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Button URL"
                  label-for="button_url"
                  label-cols-md="4"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="GlobeIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="button_url"
                      v-model="formData.button_url"
                      placeholder="Button URL"
                      name="button_url"
                    />
                  </b-input-group>
                  <label>be sure to use the full URL, beginning with https://www.[churchURL]</label>
                </b-form-group>
              </b-col>
              <b-col cols="12" />
            </b-row>
          </b-form>
          <b-card-footer>
            <b-container class="full-width no-padding">
              <b-row>
                <b-col
                  cols="6"
                >
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    type="reset"
                    class="mt-2"
                    @click.prevent="showConfirmResetForm"
                  >
                    Reset
                  </b-button>
                </b-col>
                <b-col
                  cols="6"
                  class="justify-content-end d-flex"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    :class="['mt-2']"
                    :disabled="isSubmitting"
                    @click="submitForm"
                  >
                    Save changes
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
          </b-card-footer>
        </b-card>
      </b-col>
      <b-col lg="6">
        <b-card
          :class="['browser-mockup with-backdrop with-modal with-url no-body',{
            'ty-page-high-contrast' : !ctaWidgetClassess.lowContrast,
            'ty-page-low-contrast' : ctaWidgetClassess.lowContrast,
            'ty-page-dark-mode' : ctaWidgetClassess.darkMode
          }]"
        >
          <b-card-header>
            &nbsp;
          </b-card-header>
          <div class="cta-elem-holder">

            <div
              style="width: 500px; min-height: 60px; font-family: Helvetica, Arial, serif"
              :class="['cta-elem',formData.h_placement]"
            >
              <div
                v-if="formData.title"
                class="cta-thankyou-title"
                :style="[formData.background_file_url ? { backgroundImage: `url(${formData.background_file_url})` } : null]"
              >
                <h5 :style="{color: getTextColorBasedOnContrastAndDarkMode()}">
                  {{ formData.title }}
                </h5>
              </div>
              <div
                v-if="formData.message"
                class="cta-thankyou-message"
              >
                <span v-html="messageWithBrs" />
              </div>
              <div
                v-if="formValid"
                class="cta-thankyou-yt"
              >
                <b-embed
                  v-if="formData.video_url !== '' && youtubeID !== ''"
                  type="iframe"
                  :src="`https://www.youtube.com/embed/${youtubeID}?rel=0`"
                  allowfullscreen
                />
              </div>
              <div
                v-if="formData.button_text !== ''"
                class="cta-thankyou-button"
              >
                <button
                  v-if="ctaWidgetSettings"
                  :style="{backgroundColor : `#${ctaWidgetSettings.button_color}`}"
                >
                  {{ formData.button_text }}
                </button>
              </div>
            </div>

          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BButton,
  BCard,
  BCardFooter,
  BCardHeader,
  BCol,
  BContainer,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BRow,
  BTab,
  BTabs,
  BEmbed,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { cloneDeep } from 'lodash'
import store from '@/store'
import AccountService from '@/services/AccountService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { escapeHtmlAndConvertNlToBr, handleValidationError } from '@/utils/helpers'

export default {
  components: {
    BButton,
    BForm,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BTabs,
    BTab,
    BInputGroup,
    BInputGroupPrepend,
    BContainer,
    BInputGroupAppend,
    BCardHeader,
    BFormInvalidFeedback,
    BCardFooter,
    BEmbed,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isSubmitting: false,
      formData: {
        type: 'default',
        title: 'Thank you',
        button_text: 'Visit our website',
      },
      ctaWidgetSettings: null,
      ctaWidgetClassess: {
        lowContrast: false,
        darkMode: false,
      },
      backgroundFile: null,
      tabIndex: 0,
      youtubeID: '',
      showCtaNoContent: true,
      touched: false,
      required,
    }
  },
  computed: {
    formValid: {
      set() {
        return typeof this.formData !== 'undefined' && this.formData.video_url.length > 0 && this.youtubeID !== ''
      },
      get() {
        if (this.touched && this.formData.video_url) {
          if (this.formData.video_url.length > 0) {
            return this.youtubeID !== ''
          }
          return null
        }
        return null
      },
    },
    // Convert new lines to <br> and escape rest of the html.
    // The result can be put directly into v-html without XSS risk because it's been escaped.
    messageWithBrs() {
      return escapeHtmlAndConvertNlToBr(`${this.formData.message}`)
    },
  },
  watch: {
    tabIndex(newTabIndex) {
      if (newTabIndex === 1) {
        this.formData.type = 'redirect'
        return
      }
      this.formData.type = 'default'
    },
  },
  created() {
    this.resetForm()
  },
  methods: {
    async submitForm() {
      this.isSubmitting = true
      const data = {
        widgets: {
          scheduled: {
            type: this.formData.type,
            button_text: this.formData.button_text,
            button_url: this.formData.button_url,
            title: this.formData.title,
            message: this.formData.message,
            video_url: this.formData.video_url,
            redirect_url: this.formData.redirect_url,
            background_file_id: this.formData.background_file_id,
          },
        },
      }
      try {
        await AccountService.updateAccountSettings(data).then(response => {
          store.dispatch('auth/setAuthUser', response.data.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: 'Updated successfully',
              variant: 'success',
            },
          })
        })
      } catch (error) {
        handleValidationError(error, this)
      } finally {
        this.isSubmitting = false
      }
    },
    async uploadFile(e) {
      const data = new FormData()
      data.append('file', e.target.files[0])
      const apiResponse = await AccountService.uploadFile(data)
      if (apiResponse.status === 200) {
        const backgroundFile = apiResponse.data.data
        // $set is needed to the change to be picked up by Vue
        this.$set(this.formData, 'background_file_id', backgroundFile.id)
        this.$set(this.formData, 'background_file_url', encodeURI(backgroundFile.absolute_url))
        this.$set(this.formData, 'background_file_name', backgroundFile.name)
      }
    },
    deleteFile() {
      this.$delete(this.formData, 'background_file_id')
      this.$set(this.formData, 'background_file_url', process.env.VUE_APP_DEFAULT_BACKGROUND_FILE_URL)
      this.$set(this.formData, 'background_file_name', 'Default background')
    },
    showConfirmResetForm() {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want reset the form', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.resetForm()
          }
        })
    },
    resetForm() {
      const widgetsSettings = store.getters['auth/account'].settings.widgets
      this.formData = cloneDeep(widgetsSettings.scheduled)
      this.ctaWidgetSettings = cloneDeep(widgetsSettings.cta)
      this.tabIndex = this.formData.type === 'redirect' ? 1 : 0
      this.checkVideoUrl()
      this.setThankYouWidgetPreviewClasses()
    },
    matchYoutubeUrl(url) {
      const p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
      if (url.match(p)) {
        return url.match(p)[1]
      }
      return false
    },
    checkVideoUrl() {
      this.touched = true
      if (this.formData.video_url) {
        const checkedURL = this.matchYoutubeUrl(this.formData.video_url)
        if (!checkedURL) {
          this.youtubeID = ''
          this.formValid = false
        } else {
          this.youtubeID = checkedURL
          this.formValid = true
        }
      }
    },
    checkColorContrastRatio(color1, color2) {
      function hexToRgb(hex) {
        if (!/^#[0-9a-f]{3}(?:[0-9a-f]{3})?$/i.test(color1)) {
          return { r: 0, g: 0, b: 0 }
        }

        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
        const converted = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(converted)
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        } : null
      }
      function luminance(r, g, b) {
        const a = [r, g, b].map(v => {
          v /= 255
          return v <= 0.03928
            ? v / 12.92
            : Math.pow((v + 0.055) / 1.055, 2.4)
        })
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
      }
      const color1rgb = hexToRgb(color1)
      const color2rgb = hexToRgb(color2)

      const color1luminance = luminance(color1rgb.r, color1rgb.g, color1rgb.b)
      const color2luminance = luminance(color2rgb.r, color2rgb.g, color2rgb.b)

      const ratio = color1luminance > color2luminance
        ? ((color2luminance + 0.05) / (color1luminance + 0.05))
        : ((color1luminance + 0.05) / (color2luminance + 0.05))

      return ratio
    },
    setThankYouWidgetPreviewClasses() {
      console.log(this.ctaWidgetSettings)
      if (this.ctaWidgetSettings.theme === 'dark') {
        this.ctaWidgetClassess.darkMode = true
      }
      if (this.checkColorContrastRatio(`#${this.ctaWidgetSettings.button_color}`, '#000000') > 1 / 6.5) {
        this.ctaWidgetClassess.lowContrast = true
      }
    },
    getTextColorBasedOnContrastAndDarkMode() {
      const defaultColor = '#6f42c1'
      const defaultLightColor = '#FFFFFF'
      const { theme, button_color } = this.ctaWidgetSettings
      if (theme === 'dark') {
        if (this.checkColorContrastRatio(`#${button_color}`, '#000000') > 1 / 6.5) {
          return defaultLightColor
        }
        return `#${button_color}`
      }
      if (this.checkColorContrastRatio(`#${button_color}`, '#000000') > 1 / 6.5) {
        return `#${button_color}`
      }
      return defaultColor
    },
  },
}
</script>
<style lang="scss">
iframe#youtube-player-1 {
  width: 100%;
  height: 230px;
}
</style>
<style scoped>
.container.full-width{
  max-width:100%;
}
.container-no-padding{
  padding-left:0;
  padding-right:0;
}
.full-width{
  width:100%;
  full-width:100%;
}
.no-padding-l{
  padding-left:0;
}
.no-padding-r{
  padding-right:0;
}
/* Browser */
.browser-mockup.with-tab:after {
  display: block;
  position: absolute;
  content: '';
  top: -2em;
  left: 5.5em;
  width: 20%;
  height: 0em;
  border-bottom: 2em solid white;
  border-left: 0.8em solid transparent;
  border-right: 0.8em solid transparent;
}
.card.browser-mockup .card-body{
  padding:0;
}
.card.browser-mockup.with-url .card-header:after {
  display: block;
  position: absolute;
  content: 'Plan your visit';
  top: 0.8em;
  width: calc(100% - 8em);
  height: 2.1em;
  font-size: 12px;
  padding: 4px 4px 4px 12px;
  box-sizing: border-box;
  left: 6.4em !important;
  color: #9c9c9c;
  background:#FFF;
  border-radius:100px;
}
.card.browser-mockup.with-backdrop:before{
  content:'';
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:rgba(0,0,0,0.3);
  border-radius:0.428rem;
}
.card.browser-mockup.with-modal .cta-elem{
  min-width:390px;
  border-radius: 10px;
  overflow: hidden;
}
.browser-mockup > * {
  display: block;
}
.card.browser-mockup {
  flex: 1;
  min-height: 440px;
  position:relative;
  border: 1px solid #e2e2e2;
}
.card.browser-mockup .card-header{
  background-color:#F0F0F0;
  border-bottom: 1px solid #dedddd;
}
.card.browser-mockup .card-header:before{
  display: block;
  position: absolute;
  content: '';
  top: 1.3em;
  left: 1em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: #f44;
  box-shadow: 0 0 0 2px #f44, 1.5em 0 0 2px #9b3, 3em 0 0 2px #fb5;
}
/* CTA Elem */
.cta-elem{
  width: 35%;
  min-height: 60px;
  background: #F0F0F0;
  box-shadow: 9px 11px 22px rgb(6 5 5 / 6%);
  bottom: 15px;
  position: absolute;
  min-width: 300px;
  padding: 10px 20px 10px 20px;
}
.cta-elem:not(.left):not(.right){
  left: 15px;
}
.cta-elem.left{
  left:15px;
}
.cta-elem.right{
  right:15px;
}
.cta-elem button{
  width: 100%;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid rgba(0,0,0,0.1);
  box-shadow: 1px 1px 10px rgb(0 0 0 / 10%);
  font-weight: 500;
}
.cta-explainer-text{
  width: 100%;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #DADADA;
  margin-bottom: 10px;
}
.cta_recent{
  text-align:center;
  margin-bottom:0.75rem;
  border-bottom: 1px solid #DADADA;
}
.cta-countdown{
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top:10px;
  padding-bottom:10px;
}
.cta-thankyou-title{
  background-position: center;
  background-size: cover;
  border-bottom: 1px solid #DADADA;

  padding: 20px 20px 10px 20px;
  background-color: #DADADA;
  margin-top: -11px;
  position:relative;

  margin-left: -40px;
  width: calc(100% + 80px);
  margin-bottom: 20px;
}
.cta-thankyou-title:before{
  content:'';
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:rgba(255,255,255,0.7);
  z-index:1;
}
.cta-thankyou-title h5{
  text-align:center;
  width:100%;
  z-index:2;
  position:relative;
  color: var(--purple);
  font-weight: bold;

  margin-top: 10px;
  margin-bottom: 20px !important;
  font-size: 23px;
}
.cta-thankyou-message{
  width:100%;
  padding-bottom:10px;
  border-bottom:1px solid #DADADA;
  text-align:center;
  margin-bottom: 10px;
}
.cta-thankyou-button{
  width:100%;
}
.cta-thankyou-button button{
  width: 100%;
  border: 1px solid rgba(0,0,0,0.1);
  box-shadow: 1px 1px 10px rgb(0 0 0 / 10%);
  font-weight: 500;
  background: var(--purple);
  color: #FFF;
  border-radius: 10px;
  padding: 10px 15px;
  text-align:center !important;
}
.cta-thankyou-yt{
  width:100%;
  padding-bottom:10px;
  margin-bottom:10px;
  border-bottom:1px solid #DADADA;
}
.cta-thankyou-yt .embed-responsive::before {
  width: 100%;
  min-height: 280px;
}
.cta-elem-holder{
  width:100%;
  min-height: 460px;
  padding:50px 20px;
  position:relative;
  display:flex;
  align-items: center;
  justify-content: center;
}
.cta-elem-holder .cta-elem{
  position:relative;
  top:auto !important;
  left:auto !important;
  transform:none !important;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
}
.nav-pills .nav-link{
  margin: 0 10px 0 0;
}
.nav-pills .nav-link:not(.active){
  background: #e8e8e8;
}
.invalid-tooltip{
  font-size: 0.7rem;
}
.card-footer{
  padding: 0;
  margin-top: 1rem;
}
.dark-layout .cta-elem:not(.cta-widget-dark) h1,
.dark-layout .cta-elem:not(.cta-widget-dark) h2,
.dark-layout .cta-elem:not(.cta-widget-dark) h3,
.dark-layout .cta-elem:not(.cta-widget-dark) h4,
.dark-layout .cta-elem:not(.cta-widget-dark) h5,
.dark-layout .cta-elem:not(.cta-widget-dark) h6{
  color:var(--purple);
}
.dark-layout .card-header{
  background-color:var(--dark) !important;
  border-bottom: 1px solid #484848 !important;
}
.dark-layout .card.browser-mockup{
  border: 1px solid #484848;
}
.dark-layout .cta-thankyou-message{
  color:var(--dark);
}
.dark-layout .form-control.custom-file{
  background-color: #3f4656;
  background-clip: padding-box;
  border: 1px solid #3f4656;
}
.cta-thankyou-title[style*="background-image"]{
  border-bottom:none !important;
}
.card.browser-mockup.ty-page-high-contrast .cta-thankyou-button button{
  color:#000 !important;
}
.card.browser-mockup.ty-page-dark-mode .cta-thankyou-title,
.card.browser-mockup.ty-page-dark-mode .cta-thankyou-title:before{
  background-color:rgb(39 48 69 / 79%);
}
.card.browser-mockup.ty-page-dark-mode .cta-elem{
  background:#283046;
}
.card.browser-mockup.ty-page-dark-mode .cta-thankyou-message{
  color:#b4b7bd;
  border-bottom-color: #171f3087;
}
.card.browser-mockup.ty-page-dark-mode .cta-thankyou-yt{
  border-bottom-color: #171f3087;
}
@media screen and (max-width: 768px){
  .cta-elem-holder{
    padding:50px 5px;
  }
  .cta-elem{
    min-width: initial !important;
    max-width: 100%;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .cta-thankyou-title h5{
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>
